import React from 'react'
import Container from './container'
import Footer from './footer'
import PropTypes from 'prop-types'

import '../scss/blog.scss'
import '../scss/code-theme/dracula.scss'

class Template extends React.Component {
  render() {
    const { children, author } = this.props

    return (
      <Container>
        {children}
        <Footer author={author}></Footer>
      </Container>
    )
  }
}

Template.propTypes = {
  children: PropTypes.array.isRequired,
  author: PropTypes.object.isRequired,
}

export default Template
