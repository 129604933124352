import React from 'react'
import PropTypes from 'prop-types'

const container = ({ children }) => <div>{children}</div>

container.propTypes = {
  children: PropTypes.array.isRequired,
}

export default container
