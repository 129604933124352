import React from 'react'
import PropTypes from 'prop-types'

const Home = ({ author, show }) => {
  if (show) {
    return (
      <ul className="list-inline text-left">
        <li key="home" className="list-inline-item">
          <a href="/" aria-label="Home" className="logo">
            BOXES <span class="brand-accent">+</span> ARROWS
          </a>
        </li>
      </ul>
    )
  }

  return null
}

Home.propTypes = {
  author: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
}

const Header = ({ children, author, image, home, headerOpacity = 0.3 }) => (
  <header className="masthead" style={{ backgroundImage: `url('${image}')` }}>
    <div className="overlay" style={{ opacity: headerOpacity }}></div>
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-md-10 mx-auto mt-2">
          <div className="row">
            <div className="col">
              <Home author={author} show={!home} />
            </div>
            <div className="col">
              <ul className="list-inline text-right">
                <li key="twitter" className="list-inline-item">
                  <a href={author.twitter} aria-label="Twitter">
                    <i className="fab fa-twitter"></i>
                  </a>
                </li>
                <li key="linkedin" className="list-inline-item">
                  <a href={author.linkedIn} aria-label="LinkedIn">
                    <i className="fab fa-linkedin"></i>
                  </a>
                </li>
                <li key="guthub" className="list-inline-item">
                  <a href={author.github} aria-label="GitHub">
                    <i className="fab fa-github"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-md-10 mx-auto">{children}</div>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  children: PropTypes.object.isRequired,
  author: PropTypes.object.isRequired,
  image: PropTypes.string.isRequired,
  home: PropTypes.bool,
  headerOpacity: PropTypes.number.isRequired,
}

export default Header
