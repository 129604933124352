import React from 'react'
import PropTypes from 'prop-types'

const footer = ({ author }) => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-lg-8 col-md-10 mx-auto">
          <ul className="list-inline text-center">
            <li key="twitter" className="list-inline-item">
              <a href={author.twitter} aria-label="Twitter">
                <span className="fa-stack fa-lg">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fab fa-twitter fa-stack-1x fa-inverse"></i>
                </span>
              </a>
            </li>
            <li key="linkedin" className="list-inline-item">
              <a href={author.linkedIn} aria-label="LinkedIn">
                <span className="fa-stack fa-lg">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fab fa-linkedin fa-stack-1x fa-inverse"></i>
                </span>
              </a>
            </li>
            <li key="github" className="list-inline-item">
              <a href={author.github} aria-label="GitHub">
                <span className="fa-stack fa-lg">
                  <i className="fas fa-circle fa-stack-2x"></i>
                  <i className="fab fa-github fa-stack-1x fa-inverse"></i>
                </span>
              </a>
            </li>
          </ul>
          <p className="copyright text-muted">
            Copyright &copy; BOXES <span class="brand-accent">+</span> ARROWS {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  </footer>
)

footer.propTypes = {
  author: PropTypes.object.isRequired,
}

export default footer
